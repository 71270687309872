import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { injectIntl } from "react-intl";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, DESTINATION } = publicRuntimeConfig;
import styles from "./HomeNavBar.styles.css";
import Categories from "../NavigationBar/Categories";
import Button from "../Button";
import ModalWindow from "../ModalWindow/ModalWindow";
import { DayPickerRangeController } from "react-dates";
import Search from "../NavigationBar/Search";
import FilterDialog from "../NavigationBar/FilterDialog";
import moment from "moment";
import { logout } from '../../utils/auth'

const perfectForItems = [
  {
    id: 1,
    name: "home.filter.perfert.for.one",
  },
  {
    id: 2,
    name: "home.filter.perfert.for.two",
  },
  {
    id: 3,
    name: "home.filter.perfert.for.three",
  },
  {
    id: 4,
    name: "home.filter.perfert.for.four",
  },
  {
    id: 5,
    name: "home.filter.perfert.for.five",
  },
];

const relationshipsItems = [
  {
    id: 1,
    name: "home.filter.relationships.one",
  },
  {
    id: 2,
    name: "home.filter.relationships.two",
  },
  {
    id: 3,
    name: "home.filter.relationships.three",
  },
];

const languageItems = [
  { id: 'es', name: 'ES' },
  { id: 'en', name: 'EN' },
  { id: 'fr', name: 'FR' },
  { id: 'de', name: 'DE' },
  { id: 'pt', name: 'PT' },
  { id: 'it', name: 'IT' }
]

const HomeNavBar = (props) => {
  var auxPerfectFor = {}
  if(props.perfectFor){
    auxPerfectFor = perfectForItems.filter((p) => p.id == props.perfectFor)[0]
  }

  var auxRelationships = {}
  if(props.relationships){
    auxRelationships = relationshipsItems.filter((p) => p.id == props.relationships)[0]
  }

  const [totem, setTotem] = useState('');
  const [isLogged, setIsLogged] = useState(props.user ? props.user.is_logged : false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isVisible, setIsVisible] = useState((props.autoopen ? (props.autoopen === "true") : false));
  const [isExpanded, setIsExpanded] = useState(false);
  const [sDate, setStartDate] = useState(props.startDate ? moment(props.startDate) : null);
  const [eDate, setEndDate] = useState(props.endDate ? moment(props.endDate) : null);
  const [destination, setDestination] = useState(props.destination || {});
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [perfectFor, setPerfectFor] = useState( auxPerfectFor );
  const [relationships, setRelationships] = useState( auxRelationships );
  const [showFilter, setShowFilter] = useState( false );
  const [showMap, setShowMap] = useState( false );
  const [showSearchInput, setShowSearchInput] = useState( false );
  const [filters, setFilters] = useState({})

  const [language, getLanguage] = useState({});
  const router = useRouter();

  const desktopLocationRef = useRef();
  const desktopDatesRef = useRef();
  const desktopRelationshipsRef = useRef();
  const desktopPerfectForRef = useRef();
  const desktopCityRef = useRef();
  const desktopLanguageRef = useRef();
  const mobileLanguageRef = useRef();
  const desktopProfileRef = useRef();

  const locationModalRef = useRef();
  const datesModalRef = useRef();
  const perfectForModalRef = useRef();
  const relationshipsModalRef = useRef();
  const cityModalRef = useRef();
  const languageModalRef = useRef();
  const profileModalRef = useRef();

  const modalsRefs = [
    locationModalRef,
    datesModalRef,
    perfectForModalRef,
    relationshipsModalRef,
    cityModalRef,
    languageModalRef,
    profileModalRef
  ];

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  const isScrollingEvent = (ev) => {
    setIsScrolling(
      document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0
    );
  };

  useEffect(() => {
    setTotem(localStorage.getItem('totem'));
  });

  useEffect(() => {
    document.addEventListener("scroll", isScrollingEvent);
    return () => {
      document.removeEventListener("scroll", isScrollingEvent);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    return () => body.classList.remove("modal-open");
  }, [isVisible]);

  const getBackgroundStyle = () => {
    if (props.background) {
      return {
        backgroundImage: `url(${
          props.background.normal ? props.background.normal : props.background
        })`,
      };
    }
    return {};
  };
  const backgroundStyle = getBackgroundStyle();
  const mainStyles = isScrolling ? styles.isScrolling : undefined;
  const expandedStyle = isExpanded ? styles.isExpanded : undefined;

  const goToSearch = () => {
    console.log(relationships);
    console.log(perfectFor);
    let relationshipsId = "";
    if (relationships.id !== undefined) {
      relationshipsId = relationships.id;
    }
    let perfectForId = "";
    if (perfectFor.id !== undefined) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (sDate !== null) {
      start = sDate.format("YYYYMMDD");
    }
    let end = "";
    if (eDate !== null) {
      end = eDate.format("YYYYMMDD");
    }
    if(APP === 'discoolver' && destination.rawId === 'ronda'){
      router.push(`https://turismoderonda.es/${props.locale}/${destination.rawId}/for-you?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`)
    }else{
      router.push(
        `/${props.locale}/${destination.rawId}/for-you?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`
      );
    }
  };

  const goToPlan = () => {
    let relationshipsId = "";
    if (relationships.id !== undefined) {
      relationshipsId = relationships.id;
    }
    let perfectForId = "";
    if (perfectFor.id !== undefined) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (sDate !== null) {
      start = sDate.format("YYYYMMDD");
    }
    let end = "";
    if (eDate !== null) {
      end = eDate.format("YYYYMMDD");
    }
    if(APP === 'discoolver' && destination.rawId === 'ronda'){
      router.push(`https://turismoderonda.es/${props.locale}/${destination.rawId}/plan-my-trip?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`)
    }else{
      router.push(
        `/${props.locale}/${destination.rawId}/plan-my-trip?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`
      );
    }
  };

  const hideAll = () => {
    modalsRefs.forEach((d) => {
      if (d.current && d.current.hide) {
        d.current.hide();
      }
    });
  };

  const onChangeCity = (city) => {
    if(APP === 'discoolver' && city=== 'ronda'){
      router.push(`https://turismoderonda.es/${props.locale}/${city}`)
    }else{
      let url_path = router.asPath.split('/')
      if(url_path[1] === ''){
        url_path[1] = props.locale;
      }
      url_path[2] = city
      router.push(url_path.join('/'))
    }
  }

  const onChangeLang = (lang) => {
    let url_path = router.asPath.split('/')
    url_path[1] = lang
    router.push(url_path.join('/'))
  }

  const onChangeProfile = (profile) => {
    router.push(`/${props.intl.locale}/${props.destination.rawId}/profile${profile}`)
  }

  const onSearchInputVisibilityChanged = () => {
    setShowSearchInput( !showSearchInput )
  }

  const onFilterVisibilityChanged = () => {
    setShowFilter( !showFilter )
  }
  var goHome = {
    'href': `/?locale=${props.intl.locale}&destination=${props.destination.rawId}`,
    'as': `/${props.intl.locale}/${props.destination.rawId}`
  }
  if(totem) {
    goHome = {
      'href': `/?locale=${props.intl.locale}&destination=${props.destination.rawId}&totem=${totem}`,
      'as': `/${props.intl.locale}/${props.destination.rawId}/totem/${totem}`
    }
  }
  return (
    <>
      <div
        id={styles.homeBar}
        className={classnames(mainStyles, expandedStyle)}
      >
        {props.background && (
          <div class={styles.image} style={backgroundStyle}></div>
        )}
        <div className={styles.infosContainer}>
          <div className={styles.infos}>
            <h2>{`${props.intl.formatMessage({ id: "header.hero.title" })} ${props.destination.name}`}</h2>
            <span>{`${props.intl.formatMessage({ id: "header.hero.line1" })} ${
              props.intl.formatMessage({ id: "header.hero.line2" })
            }`}</span>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          mainStyles,
          expandedStyle,
          styles.navbarContainer
        )}
      >
        <div className={classnames(styles.navbar, styles.gridContainer)}>
          <div className={styles.brandContainer}>
            <Link
              href={goHome.href}
              as={goHome.as}
            >
              <a className={classnames(styles.brand, styles[APP])}>
                <span>Discoolver.com</span>
              </a>
            </Link>
            {APP === 'discoolver' &&
              <a
                ref={desktopCityRef}
                onClick={() => {
                  hideAll();
                  if (!cityModalRef.current.isVisible()) {
                    cityModalRef.current.show({
                      position: getElementBounds(desktopCityRef.current),
                    });
                    return;
                  }
                  cityModalRef.current.hide();
                }}
                className={styles.citySelector}
              >
                <span>
                  {props.destination.name}
                </span>
              </a>
            }
          </div>
          <div className={styles.commands}>
            <div
              className={classnames(
                styles.smallFakeInput,
                styles.commandsContainer
              )}
            >
              <button
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  window.scrollTo(0, 0);
                }}
                className={styles.smallButton}
              >
                <span className={styles.smallLabel}>
                  {props.intl.formatMessage({ id: "search.info.search" })}
                </span>
                <div className={styles.searchIcon}></div>
              </button>
            </div>
          </div>
          {
            totem &&
            <div className={styles.menubar}>
              <Link
                href={`/totem?locale=${props.intl.locale}&destination=${props.destination.rawId}&totem=${totem}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/totem/${totem}`}
              >
                <a className={styles.buttonBackTotem}>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.back.totem" })}
                  </span>
                </a>
              </Link>
              <a
                ref={desktopLanguageRef}
                onClick={() => {
                  hideAll();
                  if (!languageModalRef.current.isVisible()) {
                    languageModalRef.current.show({
                      position: getElementBounds(desktopLanguageRef.current),
                    });
                    return;
                  }
                  languageModalRef.current.hide();
                }}
                className={styles.languageSelector}
              >
                <span>
                  {props.intl.locale}
                </span>
              </a>
            </div>
          }
          {
            !totem &&
            <div className={styles.menubar}>
              <Link
                href={`/plan-my-trip?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/plan-my-trip`}
              >
                <a>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.plan-my-trip" })}
                  </span>
                </a>
              </Link>
              <Link
                href={`/calendar?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/calendar`}
              >
                <a>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.calendar" })}
                  </span>
                </a>
              </Link>
              {!isLogged && (
                <Link
                  href={`/signin?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                  as={`/${props.intl.locale}/${props.destination.rawId}/signin`}>
                  <a>
                    <span>
                      {props.intl.formatMessage({ id: "header.menu.join" })}
                    </span>
                  </a>
                </Link>
              )}
              {isLogged && (
                <div
                  ref={desktopProfileRef}
                  onClick={() => {
                    hideAll();
                    if (!profileModalRef.current.isVisible()) {
                      profileModalRef.current.show({
                        position: getElementBounds(desktopProfileRef.current),
                      });
                      return;
                    }
                    profileModalRef.current.hide();
                  }}
                  style={
                    {
                      background: `url(${props.user.avatar ?? '/static/icons/profile.svg'})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }
                  }
                  className={styles.avatar}>
                </div>
              )}
              <a
                ref={desktopLanguageRef}
                onClick={() => {
                  hideAll();
                  if (!languageModalRef.current.isVisible()) {
                    languageModalRef.current.show({
                      position: getElementBounds(desktopLanguageRef.current),
                    });
                    return;
                  }
                  languageModalRef.current.hide();
                }}
                className={styles.languageSelector}
              >
                <span>
                  {props.intl.locale}
                </span>
              </a>

            </div>
          }
        </div>
        <div className={classnames(styles.desktopBar, styles.gridContainer)}>
          <div
            className={classnames(
              styles.commandsContainer,
              expandedStyle,
              styles.desktopInput
            )}
          >
            <button
              ref={desktopLocationRef}
              onClick={() => {
                if(DESTINATION === ''){
                  if (!locationModalRef.current.isVisible()) {
                    hideAll();
                    locationModalRef.current.show({
                      position: getElementBounds(desktopLocationRef.current),
                    });
                    return;
                  }
                  locationModalRef.current.hide();
                }
              }}
              className={classnames(styles.option, styles.location)}
            >
              <span className={styles.optionLabel}>
                {props.intl.formatMessage({
                  id: "home.home.select_the_city",
                })}
              </span>
              <span className={styles.optionValue}>{destination.name}</span>
            </button>
            <div
              ref={desktopDatesRef}
              className={classnames(styles.optionm, styles.dates)}
            >
              <button
                onClick={() => {
                  if (!datesModalRef.current.isVisible()) {
                    hideAll();
                    datesModalRef.current.show({
                      position: getElementBounds(desktopDatesRef.current),
                    });
                    return;
                  }
                  datesModalRef.current.hide();
                }}
                className={classnames(styles.option, styles.start)}
              >
                <span className={styles.optionLabel}>
                  {props.intl.formatMessage({
                    id: "home.filter.dates.short-start",
                  })}
                </span>
                <span className={styles.optionValue}>
                  {sDate !== null
                    ? props.intl.formatDate(sDate.toDate(), {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : props.intl.formatMessage({
                        id: "commons.add-dates",
                      })}
                </span>
              </button>
              <button
                onClick={() => {
                  hideAll();
                  if (!datesModalRef.current.isVisible()) {
                    datesModalRef.current.show({
                      position: getElementBounds(desktopDatesRef.current),
                    });
                    return;
                  }
                  datesModalRef.current.hide();
                }}
                className={classnames(styles.option, styles.end)}
              >
                <span className={styles.optionLabel}>
                  {props.intl.formatMessage({
                    id: "home.filter.dates.short-end",
                  })}
                </span>
                <span className={styles.optionValue}>
                  {eDate !== null
                    ? props.intl.formatDate(eDate.toDate(), {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : props.intl.formatMessage({
                        id: "commons.add-dates",
                      })}
                </span>
              </button>
            </div>
            <button
              ref={desktopRelationshipsRef}
              onClick={() => {
                hideAll();
                if (!relationshipsModalRef.current.isVisible()) {
                  relationshipsModalRef.current.show({
                    position: getElementBounds(desktopRelationshipsRef.current),
                  });
                  return;
                }
                relationshipsModalRef.current.hide();
              }}
              className={classnames(styles.option, styles.city)}
            >
              <span className={styles.optionLabel}>
                {props.intl.formatMessage({
                  id: "home.filter.relationships.zero",
                })}
              </span>
              <span className={styles.optionValue}>
                {props.intl.formatMessage({
                  id: relationships.id ? relationships.name : "commons.select",
                })}
              </span>
            </button>
            <button
              ref={desktopPerfectForRef}
              onClick={() => {
                hideAll();
                if (!perfectForModalRef.current.isVisible()) {
                  perfectForModalRef.current.show({
                    position: getElementBounds(desktopPerfectForRef.current),
                  });
                  return;
                }
                perfectForModalRef.current.hide();
              }}
              className={classnames(styles.option, styles.company)}
            >
              <span className={styles.optionLabel}>
                {props.intl.formatMessage({
                  id: "home.filter.perfert.for.zero",
                })}
              </span>
              <span className={styles.optionValue}>
                {props.intl.formatMessage({
                  id: perfectFor.id ? perfectFor.name : "commons.select",
                })}
              </span>
            </button>
            <div className={styles.contentSearchIcon}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  goToSearch();
                }}
                className={styles.searchIconText}
              >Buscar</a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  goToPlan();
                }}
                className={styles.searchIconText}
              >{props.intl.formatMessage({ id: "search.info.get-a-route" })}</a>
            </div>
          </div>
        </div>
        <div className={classnames(styles.mobileBar, styles.gridContainer)}>
          <div
            className={classnames(styles.mobileInput, styles.commandsContainer)}
          >
            <button
              onClick={() => {
                setIsVisible(true);
              }}
              className={styles.smallButton}
            >
              <span className={styles.smallLabel}>
                {props.intl.formatMessage({ id: "search.info.search" })}
              </span>
              <div className={styles.searchIcon}></div>
            </button>
          </div>
          <a
            ref={mobileLanguageRef}
            onClick={() => {
              hideAll();
              if (!languageModalRef.current.isVisible()) {
                languageModalRef.current.show();
                return;
              }
              languageModalRef.current.hide();
            }}
            className={classnames(styles.languageSelector, styles.mobileselector, styles.mobileLanguage)}
          >
            <span>
              {props.intl.locale}
            </span>
          </a>
        </div>
      </div>
      {
        !totem && props.categories && props.categories.length > 0 &&
        <div className={classnames(styles.gridContainer, styles.categories)}>
          <Categories
            selected={props.selected}
            items={props.categories}
            onCategoryChange={props.onCategoryChange}
          />
          <div className={styles.navButtons}>
            <p className={styles.results}>
              <b>{props.results}</b> {props.intl.formatMessage({ id: "search.info.results" })}
            </p>
            {
              props.printFilter &&
              <div className={classnames([styles.iconButton], {[styles.selected]: showFilter})}>
                <button
                  onClick={onFilterVisibilityChanged}
                  className={styles.action}>
                  <span className={classnames([styles.icon, styles.iconfilter])}></span>
                </button>
              </div>
            }

            {
              props.printMap &&
              <div className={classnames([styles.iconButton], {[styles.selected]: showMap})}>
                <button
                    onClick={() => {
                      setShowMap( !showMap )
                      props.onMapChange()
                    }}
                    className={styles.action}>
                    <span className={classnames([styles.icon, styles.iconmap])}></span>
                </button>
              </div>
            }

            <div className={classnames([styles.iconButton], {[styles.selected]: showSearchInput})}>
              <button
                onClick={onSearchInputVisibilityChanged}
                className={styles.action}>
                <span className={classnames([styles.icon, styles.iconsearch])}></span>
              </button>
            </div>
          </div>
        </div>
      }

      {showSearchInput &&
        <div className={classnames({[styles.gridContainer]: true, [styles.searchbar]:true, [styles.showKeyboard]: !!totem})}>
          <Search
            query={props.query}
            totem={totem}
            placeholder={props.intl.formatMessage({ id: "search.info.search" })}
            onSearchChanged={props.onSearchChanged}
            onChangeSearchVisibility={onSearchInputVisibilityChanged} />
        </div>
      }

      {
        showFilter &&
        <div className={classnames(styles.gridContainer, styles.filterbar)}>
          <FilterDialog
            filters={props.filters}
            filtersActive={props.filtersActive}
            show_filters={showFilter}
            filtersText={props.intl.formatMessage({ id: "filter.filtersText" })}
            filterText={props.intl.formatMessage({ id: "filter.filterText" })}
            closeFiltersText={props.intl.formatMessage({ id: "filter.closeFiltersText" })}
            buttonAcceptText={props.intl.formatMessage({ id: "filter.buttonAcceptText" })}
            allItemsAreSelectedText={props.intl.formatMessage({ id: "filter.allItemsAreSelectedText" })}
            selectAllText={props.intl.formatMessage({ id: "filter.selectAllText" })}
            searchText={props.intl.formatMessage({ id: "filter.searchText" })}
            selected={filters}
            onChangeFiltersVisibility={onFilterVisibilityChanged}
            subcategories={props.category.subcategories}
            onSubcategoryChange={props.onSubcategoryChange}
            subcategorySelected={props.subcategory_selected}
            subcategoryFilterText={props.intl.formatMessage({ id: "filter.subcategoryFilterText" })}
            onFilterChanged={ (selected, filter) => {
                filters[filter.idFilter] = selected
                setFilters(filters)
                if(props.onChangeFilter !== undefined){
                    props.onChangeFilter(filters);
                }
            }} />
        </div>
      }

      {isVisible && (
        <div className={styles.mobileOptions}>
          <div className={styles.filtersContainer}>
            <div className={styles.titleBar}>
              <button
                className={classnames(styles.filterIcon, styles.close)}
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                close
              </button>
              <div className={styles.contentSelector}>
                <a
                  ref={desktopLanguageRef}
                  onClick={() => {
                    hideAll();
                    if (!languageModalRef.current.isVisible()) {
                      languageModalRef.current.show();
                      return;
                    }
                    languageModalRef.current.hide();
                  }}
                  className={classnames(styles.languageSelector, styles.mobileselector)}
                >
                  <span>
                    {props.intl.locale}
                  </span>
                </a>
                {APP === 'discoolver' &&
                  <a
                  ref={desktopCityRef}
                    onClick={() => {
                      hideAll();
                      if (!cityModalRef.current.isVisible()) {
                        cityModalRef.current.show();
                        return;
                      }
                      cityModalRef.current.hide();
                    }}
                    className={classnames(styles.citySelector, styles.mobileselector)}
                  >
                    <span>
                      {props.destination.name}
                    </span>
                  </a>
                }
              </div>
              {props.showFilters && (
                <button
                  className={classnames(styles.filterIcon, styles.filters)}
                  onClick={() => {
                    setIsVisible(false);
                  }}
                >
                  filters
                </button>
              )}
            </div>
            <div className={styles.title}>
              <span>
                {props.intl.formatMessage({
                  id: "search.info.quick-your-search",
                })}
              </span>
            </div>
            <div>
              <Search
                query={props.query}
                placeholder={props.intl.formatMessage({ id: "search.info.search" })}
                onSearchChanged={props.onSearchChanged}
                showButton={true} />

              <br/>
            </div>
            <div className={styles.title}>
              <span>
                {props.intl.formatMessage({
                  id: "search.info.advanced-your-search",
                })}
              </span>
            </div>
            <div className={styles.filtersOptions}>
              <button
                onClick={() => {
                  if(DESTINATION === ''){
                    locationModalRef.current.show();
                  }
                }}
                class={styles.commandOption}
              >
                <span>
                  {destination.rawId
                    ? destination.name
                    : props.intl.formatMessage({
                        id: "home.home.select_the_city",
                      })}
                </span>
              </button>
              <div className={styles.commandOptionContainer}>
                <button
                  onClick={() => {
                    datesModalRef.current.show();
                  }}
                  class={styles.commandOption}
                >
                  <span>
                    {sDate !== null
                      ? props.intl.formatDate(
                          sDate.toDate(), // your date variable
                          {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          }
                        )
                      : props.intl.formatMessage({
                          id: "home.filter.dates.start",
                        })}
                  </span>
                </button>
                <button
                  onClick={() => {
                    datesModalRef.current.show();
                  }}
                  class={styles.commandOption}
                >
                  <span>
                    {eDate !== null
                      ? props.intl.formatDate(
                          eDate.toDate(), // your date variable
                          {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                          }
                        )
                      : props.intl.formatMessage({
                          id: "home.filter.dates.end",
                        })}
                  </span>
                </button>
              </div>
              <button
                onClick={() => {
                  relationshipsModalRef.current.show();
                }}
                class={styles.commandOption}
              >
                <span>
                  {props.intl.formatMessage({
                    id: relationships.id
                      ? relationships.name
                      : "home.filter.relationships.zero",
                  })}
                </span>
              </button>
              <button
                onClick={() => {
                  perfectForModalRef.current.show();
                }}
                class={styles.commandOption}
              >
                <span>
                  {props.intl.formatMessage({
                    id: perfectFor.id
                      ? perfectFor.name
                      : "home.filter.perfert.for.zero",
                  })}
                </span>
              </button>
            </div>
            <div className={styles.filterButtons}>
              <Button
                onClick={goToSearch}
                label={props.intl.formatMessage({
                  id: "section.title.see-all-results",
                })}
              />
              <Button
                onClick={goToPlan}
                label={props.intl.formatMessage({
                  id: "search.info.get-a-route",
                })}
              />
            </div>
          </div>
        </div>
      )}
      <ModalWindow
        title={props.intl.formatMessage({
          id: "home.home.select_the_city",
        })}
        ref={locationModalRef}
      >
        {props.destinations.map((c) => {
          return (
            <div key={c.id} className={styles.groupItem}>
              <span className={styles.destinationTitle}>{c.name}</span>
              {c.destinations.map((d) => {
                return (
                  <button
                    onClick={() => {
                      setDestination(d);
                      locationModalRef.current.hide();
                    }}
                    key={d.rawId}
                    className={classnames([
                      styles.modalItem,
                      destination.rawId === d.rawId
                        ? styles.selected
                        : undefined,
                    ])}
                  >
                    <span>{d.name}</span>
                  </button>
                );
              })}
            </div>
          );
        })}
      </ModalWindow>
      <ModalWindow
        title={props.intl.formatMessage({
          id: "home.filter.dates.dates",
        })}
        ref={datesModalRef}
        classnameContent={styles.modalDatePicker}
        classnameContainer={styles.modalDatePickerContainer}
      >
        <DayPickerRangeController
          orientation="vertical"
          withPortal
          withFullScreenPortal
          minDate={moment()}
          startDate={sDate}
          endDate={eDate}
          onDatesChange={({ startDate, endDate }) => {
            setEndDate(endDate);
            setStartDate(startDate);
            console.log({ startDate, endDate });
          }}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => {
            setFocusedInput(!focusedInput ? "startDate" : focusedInput);
          }}
        />
      </ModalWindow>
      <ModalWindow
        title={props.intl.formatMessage({
          id: "home.filter.perfert.for.zero",
        })}
        ref={perfectForModalRef}
      >
        {perfectForItems.map((d) => {
          return (
            <button
              onClick={() => {
                setPerfectFor(d);
                perfectForModalRef.current.hide();
              }}
              key={d.id}
              className={classnames([
                styles.modalItem,
                perfectFor.id === d.id ? styles.selected : undefined,
              ])}
            >
              <span>
                {props.intl.formatMessage({
                  id: d.name,
                })}
              </span>
            </button>
          );
        })}
      </ModalWindow>
      <ModalWindow
        title={props.intl.formatMessage({
          id: "home.filter.relationships.zero",
        })}
        ref={relationshipsModalRef}
      >
        {relationshipsItems.map((d) => {
          return (
            <button
              onClick={() => {
                setRelationships(d);
                relationshipsModalRef.current.hide();
              }}
              key={d.id}
              className={classnames([
                styles.modalItem,
                relationships.id === d.id ? styles.selected : undefined,
              ])}
            >
              <span>
                {props.intl.formatMessage({
                  id: d.name,
                })}
              </span>
            </button>
          );
        })}
      </ModalWindow>

      <ModalWindow
        title={''}
        ref={cityModalRef}
      >
        {props.destinations.map((c) => {
          return (
              c.destinations.map((d) => {
                return (
                  <button
                    onClick={() => {
                      onChangeCity(d.rawId)
                      cityModalRef.current.hide();
                    }}
                    key={d.rawId}
                    className={classnames([
                      styles.modalItem,
                      props.destination.rawId === d.rawId ? styles.selected : undefined,
                    ])}
                  >
                    <span>
                      {d.name}
                    </span>
                  </button>
                );
              })
          );
        })}
      </ModalWindow>

      <ModalWindow
        title={''}
        ref={languageModalRef}
      >
        {languageItems.map((d) => {
          return (
            <button
              onClick={() => {
                onChangeLang(d.id)
                languageModalRef.current.hide();
              }}
              key={d.id}
              className={classnames([
                styles.modalItem,
                props.intl.locale === d.id ? styles.selected : undefined,
              ])}
            >
              <span>
                {props.intl.formatMessage({
                  id: d.name,
                })}
              </span>
            </button>
          );
        })}
      </ModalWindow>

      <ModalWindow
        title={''}
        ref={profileModalRef}
        className={styles.modalProfile}
      >
        <button
          onClick={() => {
            onChangeProfile('')
            profileModalRef.current.hide();
          }}
          key={'profile_general'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.general',
            })}
          </span>
        </button>
        <button
          onClick={() => {
            onChangeProfile('#my_categories')
            profileModalRef.current.hide();
          }}
          key={'profile_categories'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.categories',
            })}
          </span>
        </button>
        <button
          onClick={() => {
            onChangeProfile('#my_favorites')
            profileModalRef.current.hide();
          }}
          key={'profile_favorites'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.favorites',
            })}
          </span>
        </button>
        <button
          onClick={(event) => {
            event.preventDefault()
            logout(props.intl.locale)
            profileModalRef.current.hide();
          }}
          key={'profile_logout'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.logout',
            })}
          </span>
        </button>
      </ModalWindow>
    </>
  );
};

HomeNavBar.defaultProps = {
  categories: [],
  locale: "en",
  showFilters: false,
  selected: "para_ti",
  background: undefined,
  destination: undefined,
  destinations: [],
  onCategoryChange() {},
};

export default injectIntl(HomeNavBar);
